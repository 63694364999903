import * as React from 'react';
import Router from 'next/router';
import moment from 'moment';
import Text from 'components/core/Text';
import Pictogram from 'components/Pages/SessionDetails/PonderPictogram';
import imageUrlGenerator from '../../../utils/imageUrlGenerator';
import { EASE, QUALITY, DATE_FORMAT } from '../../../constants';

import {
  Content,
  CloseIcon,
  BackgroundStyled,
  HeaderTitle,
  Description,
  Header,
  HeaderTop,
  NextSession,
  NextSessionTitle,
  Date,
  Modal,
  ModalWrapper,
  SessionDetails,
  SessionDetail,
  SessionDetailValue,
  SessionDetailData,
  Logo,
  Badge,
  NextSessionLinkGrid,
} from './styles';

import dateOfNextSession from '../../../utils/dateOfNextSession';
import NextSessionButton from './NextSessionButton';

class SessionCompleted extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isBasic: false,
    };
  }

  componentDidMount() {
    const { modalData } = this.props;
    const { completedExercises } = modalData;
    const isBasic = completedExercises?.find(e => e.sessionExercise?.session?.isBasic);
    this.setState({ isBasic });
  }

  handleClose = () => {
    const { hideModal, showModal, modalData } = this.props;
    const { notViewedAchievements } = modalData;

    Router.push('/').then(() => {
      hideModal();
      if (notViewedAchievements && notViewedAchievements.length) {
        showModal('ACHIEVEMENTS');
      }
    });
  };

  render() {
    const { modalData } = this.props;
    const {
      session,
      schedule,
      ponder,
      ease = 1,
      quality = 1,
      sessionDateTimeData,
      completedExercises,
      program,
    } = modalData;

    const filteredSchedule =
      schedule && Object.keys(schedule).length
        ? Object.keys(schedule).filter(key => schedule[key])
        : null;

    const { timeLength } = sessionDateTimeData;
    const { isBasic } = this.state;

    const iconEase = ease ? EASE.find(i => i.value === ease)?.icon : '';
    const iconQuality = quality ? QUALITY.find(i => i.value === quality)?.icon : '';
    const labelEase = ease ? EASE.find(i => i.value === ease)?.name : '';
    const labelQuality = quality ? QUALITY.find(i => i.value === quality)?.name : '';

    return (
      <>
        <BackgroundStyled onClick={this.handleClose} />
        <ModalWrapper>
          <Modal>
            <Header image={imageUrlGenerator(session?.picture, '720x394', '400x257')}>
              <HeaderTop>
                <Logo />
                <CloseIcon onClick={this.handleClose} />
              </HeaderTop>
              <HeaderTitle>
                <Text variant="captionSmall" tag="span" color="white">
                  completed
                </Text>
                {session?.name && (
                  <Text variant="mainHeader" color="white" isCondensed isResponsive>
                    {session.name}
                  </Text>
                )}
              </HeaderTitle>
            </Header>
            <Content>
              <NextSessionLinkGrid>
                <div>
                  <Text variant="displayM" weight="bold" color="black" marginBottom={2}>
                    Great job!
                  </Text>
                  <Text variant="textSmall" color="black">
                    Get a head start on your next session by previewing the exercise tutorials ahead
                    of time
                  </Text>
                </div>
                <NextSessionButton
                  programId={program.program.id}
                  session={program.nextSession}
                  trackSessionId={program.nextTrackSessionId}
                />
              </NextSessionLinkGrid>
              <Text
                variant="textSmall"
                tag="h3"
                weight="bold"
                transform="uppercase"
                color="black"
                marginTop={6}
              >
                {program.program.name}&nbsp;
                <Date>{moment().format(DATE_FORMAT)}</Date>
                {isBasic && <Badge>Low intensity</Badge>}
              </Text>
              <SessionDetails>
                <SessionDetail>
                  <Pictogram size={50} icon={iconEase} style={{ margin: '10px' }} />
                  <SessionDetailValue>
                    <Text variant="captionSmall" tag="h4" color="grayLight" pl={1}>
                      Ease: {labelEase}
                    </Text>
                  </SessionDetailValue>
                </SessionDetail>

                <SessionDetail>
                  <Pictogram size={50} icon={iconQuality} />
                  <SessionDetailValue>
                    <Text variant="captionSmall" tag="h4" color="grayLight" pl={1}>
                      Quality: {labelQuality}
                    </Text>
                  </SessionDetailValue>
                </SessionDetail>
              </SessionDetails>

              <SessionDetails>
                <SessionDetail>
                  <SessionDetailData>
                    <Text
                      variant="promoHeader"
                      tag="h3"
                      weight="bold"
                      color="black"
                      transform="none"
                      isResponsive
                    >
                      {completedExercises?.length || 0}
                    </Text>
                    <SessionDetailValue>
                      <Text variant="captionSmall" tag="h4" color="grayLight" pl={1}>
                        EXERCISES
                      </Text>
                    </SessionDetailValue>
                  </SessionDetailData>
                </SessionDetail>
                <SessionDetail>
                  <SessionDetailData>
                    <Text
                      variant="promoHeader"
                      tag="h3"
                      weight="bold"
                      color="black"
                      transform="none"
                      isResponsive
                    >
                      {timeLength || 0}
                    </Text>
                    <SessionDetailValue>
                      <Text variant="captionSmall" tag="h4" color="grayLight" pl={1}>
                        DURATION
                      </Text>
                    </SessionDetailValue>
                  </SessionDetailData>
                </SessionDetail>
              </SessionDetails>

              <Text variant="textSmall" tag="h3" weight="bold" transform="uppercase" color="black">
                Ponder
              </Text>
              <Description>
                <Text color="black">{ponder}</Text>
              </Description>

              {filteredSchedule?.length ? (
                <NextSession>
                  <NextSessionTitle>
                    <Text variant="captionSmall" tag="h4" color="grayLight" align="center" mb={1}>
                      NEXT SESSION
                    </Text>
                  </NextSessionTitle>
                  <Text variant="textSmall" tag="h3" weight="bold" color="black" align="center">
                    {dateOfNextSession(filteredSchedule, moment().format())}
                  </Text>
                </NextSession>
              ) : null}
            </Content>
          </Modal>
        </ModalWrapper>
      </>
    );
  }
}

export default SessionCompleted;
